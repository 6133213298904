.service{
    width: 90%;
    margin: auto;
    padding: 2em 0;
}
.service-main{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3 , 1fr);
}
.service-item{
    display: flex;
    gap: 15px;
}
.service-item-icon{
    font-size: 24px;
    color: var(--secondary);
}
.service-item-info h2{
    font-size: 18px;
    color: var(--secondary);
}
.service-item-info p{
    font-size: 16px;
    color: var(--gray);
}

@media only screen and (max-width: 600px){
    .service-main{
        grid-template-columns: repeat(1 , 1fr);
    }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .service-main{
        grid-template-columns: repeat(2 , 1fr);
    }
}