.statistic{
    width: 100%;
    margin: 2em auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
}
.statistic-colis{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.statistic-colis-box {
    width: 220px;
    height: 130px;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.statistic-colis-box h5 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
}

.chart-circl-colis {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 20px auto;
    box-sizing: border-box;
}
.chart-circl-colis h2 {
    font-size: 1.5rem;
    font-weight: bold;
}


.statistic-colis-box span {
    font-size: 0.9em;
    font-weight: bold;
    margin-top: 8px;
}
.chart-container {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 12px;
    transition: all 0.3s ease;
    overflow: hidden;
}

.chart-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.chart-wrapper {
    position: relative;
    width: 100%;
    height: 50vh !important;
    min-height: 300px;
    margin-top: 20px;
}

.chart-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 600;
}

/* Dark Mode Support */
body[theme='dark'] .statistic-colis-box {
    background-color: #333;
    color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

body[theme='dark'] .statistic-colis-box span {
    color: #ddd;
}

.box-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.box-content h5{
    font-size: 22px;
    font-style: italic;
}
.box-content span{
    color: var(--gray);
    font-size: 12px;
}

.statistic-argent{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}
.statistic-chart{
    display: flex;
    gap: 20px;
    margin: auto;
    width: 100%;
    text-align: center;
}
.statistic-chart .chart-line-colis{
    width: 70%;
}
.statistic-chart .chart-circl-colis{
    width: 30%;
}
.rotate_phone_dashboard{
    display: none;
}
.list_notification_home{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px 0;
}
.notification_home{
    border-radius: 4px;
    border-left: 5px solid var(--limon);
    padding: 4px 8px;
    
}

@media (max-width: 1200px) {
    .chart-wrapper {
        height: 45vh !important;
    }
}

@media (max-width: 992px) {
    .statistic-chart {
        flex-direction: column;
    }
    
    .chart-container {
        width: 100%;
        margin: 10px 0;
    }

    .chart-circl-colis, 
    .chart-bar-colis {
        width: 100% !important;
        margin: 10px 0;
    }
}

@media only screen and (max-width: 600px){
    .statistic-colis{
        flex-direction: column;
    }
    .statistic-colis-box {
        width: 100%;
    }
    .statistic-argent{
        width: 100%;
        margin: 1em auto;
        flex-direction: column;
    }
    .statistic-chart{
       flex-direction: column;
    }
    .statistic-chart .chart-line-colis{
        width: 100%;
        margin: 1em auto;
    }
    .statistic-chart .chart-circl-colis{
        width: 100%;
        text-align: center;
        margin: 1em auto;
    }
  }
  @media only screen and (max-width: 500px){
    .rotate_phone_dashboard{
        display: block;
    }
    .statistic-chart , .chart-container{
        display: none;
     }
}

@media (max-width: 768px) {
    .chart-wrapper {
        height: 40vh !important;
        min-height: 250px;
    }
    
    .chart-container {
        padding: 10px;
    }
    
    .chart-container h2 {
        font-size: 1.2rem;
    }
}

@media (max-width: 576px) {
    .chart-wrapper {
        height: 35vh !important;
        min-height: 200px;
    }

    .statistic-chart {
        margin: 0;
        padding: 0;
    }
}

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    .statistic-colis{
        flex-direction: column;
    }
    .statistic-argent{
        width: 100%;
        margin: 1em auto;
        flex-direction: column;
    }
    .statistic-chart{
        flex-direction: column;
     }
     .statistic-chart .chart-line-colis{
        width: 100%;
        margin: 1em auto;
    }
    .statistic-chart .chart-circl-colis{
        width: 100%;
        margin: 1em auto;
        text-align: center;
    }
  }

  @media (max-width: 768px) {
    .chart-wrapper {
        height: 40vh !important;
    }
    
    .chart-container {
        padding: 10px;
    }
    
    .chart-container h2 {
        font-size: 1.2rem;
    }
}

  .table-container .ant-table-title {
    width: 100%; /* Assure que le titre prend toute la largeur du conteneur */
    padding: 10px; /* Supprime le padding par défaut */
    text-align: center;
    border-bottom: 2px solid #d9d9d9; /* Ajoute la bordure */
    font-weight: bold;
    font-size: 15px;
    color: #333;
}
.table-title {
    font-size: 15px;
    width: 100%; /* S'assurer que le titre prend toute la largeur du conteneur */
    font-weight: bold;
    padding: 8px;
    text-align: center;
    border-bottom: 2px solid #d9d9d9; /* Bordure sous le titre */
    color: #333; /* Couleur du texte */
    margin-bottom: 8px;
}

@keyframes highlight {
    0% {
        box-shadow: 0 0 10px rgba(24, 144, 255, 0.5);
        border-color: rgba(24, 144, 255, 0.8);
    }
    50% {
        box-shadow: 0 0 20px rgba(24, 144, 255, 1);
        border-color: rgba(24, 144, 255, 1);
    }
    100% {
        box-shadow: 0 0 10px rgba(24, 144, 255, 0.5);
        border-color: rgba(24, 144, 255, 0.8);
    }
}

.highlight-card {
    animation: highlight 1.5s infinite ease-in-out; /* Infinite loop */
}

.contact-info {
    max-width: 800px;
    margin: 0 auto;
}

.contact-section {
    margin-bottom: 2rem;
}

.info-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.info-item .icon {
    font-size: 1.5rem;
    color: var(--primary);
}

.social-media {
    margin-top: 3rem;
}

.social-links {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
}

.social-link {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    color: white;
    border-radius: 50%;
    font-size: 1.2rem;
    transition: all 0.3s ease;
}

.social-link:hover {
    transform: translateY(-3px);
    background: var(--primary-dark);
}

.whatsapp-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
    background: #25D366;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.whatsapp-button:hover {
    background: #128C7E;
}

/* Modern Contact Page Styles */
.contact-card {
    transition: all 0.3s ease;
}

.contact-card:hover {
    transform: translateY(-5px);
}

.info-card, .social-card {
    border-radius: 10px;
    transition: all 0.3s ease;
}

.info-item-modern {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.info-item-modern:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.icon-modern {
    font-size: 20px;
    color: var(--primary);
}

.social-links-modern {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.social-link-modern {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    color: white;
    transition: all 0.3s ease;
}

.social-link-modern:hover {
    transform: translateY(-5px) rotate(360deg);
}

.social-link-modern.facebook {
    background: #1877f2;
}

.social-link-modern.instagram {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}

.social-link-modern.tiktok {
    background: #000;
}

.whatsapp-button-modern {
    width: 100%;
    height: 50px;
    font-size: 18px;
    background: #25D366 !important;
    border: none !important;
    border-radius: 25px !important;
    box-shadow: 0 4px 12px rgba(37, 211, 102, 0.3);
    transition: all 0.3s ease !important;
}

.whatsapp-button-modern:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(37, 211, 102, 0.4);
    background: #128C7E !important;
}

.text-center {
    text-align: center;
}

/* Dark mode adjustments */
[data-theme='dark'] .info-item-modern:hover {
    background-color: rgba(255, 255, 255, 0.02);
}

[data-theme='dark'] .info-card,
[data-theme='dark'] .social-card {
    background-color: rgba(255, 255, 255, 0.05);
}

@media (max-width: 768px) {
    .social-links-modern {
        gap: 15px;
    }
    
    .social-link-modern {
        width: 45px;
        height: 45px;
        font-size: 18px;
    }
}
