 .contact-section{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: 2em auto;
 }
 .contact-section-main{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
 }
 .question-section{
    width: 48%;
 }
 .contact-form{
    width: 48%;
 }
 .contact-form p{
    color: var(--primary2);
    margin-top: 1em;
 }
 .contact-form-inputs > *{
    margin: 12px 0;
 }

 @media (min-width: 768px) and (max-width: 1024px) {
    .contact-section-main{
        flex-direction: column;
    }
    .question-section ,  .contact-form{
        width: 90%;
    }
}

/* For mobile devices */
@media (max-width: 767px) {
    .contact-section-main{
        flex-direction: column;
    }
    .question-section ,  .contact-form{
        width: 90%;
    }
}