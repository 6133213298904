.ant-card-hoverable:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2) !important;
}

.ant-card:hover .anticon,
.ant-card:hover svg {
  transform: scale(1.1);
}

.tool-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2) !important;
}

.tool-content {
  transition: all 0.3s ease;
}

.tool-card:hover .tool-content {
  transform: scale(1.02);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tool-card p {
  animation: fadeIn 0.5s ease-out;
}
