.cover{
    display: flex;
    width: 90%;
    margin: 5em auto;
}
.cover-info{
    width: 50%;
}
.cover-info p{
    background: var(--secondary);
    width: max-content;
    padding: 8px 26px;
    border-radius: 50px;
    font-style: italic;
    color: var(--white);
}
.cover-icon{
    color: var(--limon);
    padding-left: 8px;
}
.cover-info h1{
    font-size: 3.6em;
}
.cover-info h4{
    font-size: 24px;
    color: var(--gray);
    margin: 16px 0;
}
.cover-img{
    width: 45%;
    text-align: center;
}
.cover-link{
    background: var(--black);
    padding: 10px 24px;
    margin: 16px 0;
    border-radius: 50px;
    color: var(--white);
    display: flex;
    align-items: center;
    width: max-content;
    transition-duration: 0.3s;
    font-weight: 600;
    font-style: italic;
}

.cover-link-icon{
    margin-right:16px;
    color: var(--limon);
}
.cover-link:hover{
    color: var(--limon);
    box-shadow: 2px 2px 5px var(--limon);
}
.cover-img img{
    width: 400px;
    filter: drop-shadow(10px 10px 100px var(--black));
}

@media only screen and (max-width: 600px){
    .cover{
        flex-direction: column;
    }
    .cover h1{
        font-size: 18px;
    }
    .cover h4{
        font-size: 24px;
    }
    .cover-info{
        width: 100%;
    }
    .cover-img img{
        width: 90%;
        margin: auto;
    }
    .cover-img{
        order: -1;
    }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .cover-info{
        width: 100%;
    }
}