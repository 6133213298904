.track{
    width: 90%;
    margin: 3em auto;
    text-align: center;
    background: var(--gradien2);
    border-radius: 8px;
    color: var(--white);
    padding: 26px 16px;
}

.track h1{
    font-size: 2.6em;
}
.track p{
    font-size: 16px;
    color: var(--gray);
}
.track-input{
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 16px 0;
}
.submit-btn{
    background: var(--primary);
    color: var(--white);
    border: none;
    padding: 12px 16px;
    width: max-content;
    border-radius: 8px;
    cursor: pointer;
    transition-duration: 0.3s;
}
.submit-btn:hover{
    box-shadow: 2px 2px 5px var(--white);
}

@media only screen and (max-width: 600px){
   .track h1{
    font-size: 22px;
   }
   .track p{
    font-size: 14px;
   }
   .track-input{
    flex-direction: column;
    
   }

}
