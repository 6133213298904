.avantage{
    width: 90%;
    margin: auto;
    padding: 2em 0;
}
.avantage-main{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.avantage-item{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 15px;
    box-shadow: 3px 3px 10px  var(--gray);
    border-radius: 8px;
    transition-duration: 0.3s;
    cursor: pointer;
}
.avantage-item:hover{
    box-shadow: 3px 3px 10px  var(--primary);
    transform: translateY(-5px);
}
.avantage-item-icon{
    font-size: 24px;
    background: var(--gradien3);
    padding: 6px;
    border-radius: 4px;
    color: var(--limon);
}
.avantage-item-info h2{
    padding: 15px 0;
    color: var(--secondary);
}
.avantage-item-info p{
    color: var(--gray);
}

@media only screen and (max-width: 600px){
    .avantage-main{
        grid-template-columns: repeat(1, 1fr);
    }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .avantage-main{
        grid-template-columns: repeat(2, 1fr);
    }
}