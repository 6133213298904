.profile_information_image-img{
    border-radius: 50%;
}
.profile_information_photo{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.profile_information_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}
.profile_information_image{
    display: flex;
    gap: 16px;
    align-items: center;
}
.profile_information_header-info{
    display: flex;
    flex-direction: column;
}
.profile_verify{
    font-size: 25px;
}
.profile_information{
    display: flex;
    gap: 30px;
    width: 90%;
    margin: auto;
}

.profile_information_main{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
}
.card-payement {
    margin-bottom: 20px;
  }
  
  .card-payement-info {
    text-align: center;
  }
  
  .ant-card-meta-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .ant-card-meta-description {
    font-size: 16px;
    color: #555;
  }
  
  .container-profile{
    display: flex;
    flex-direction: column;
  }