.action_user{
    display: flex;
    gap: 10px;
}

.store_card{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3,1fr);
    gap: 16px;
}
.store_card ul{
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 2px 2px 5px var(--gray);
    padding: 12px;
    border-radius: 8px;
}

.store_card ul p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.store_card ul p span{
    font-size: 2rem;
    font-weight: 800;
    color: var(--limon);
}